import { useResetRecoilState } from 'recoil';
import {
  selectedIdIndexAtom,
  designerListAtom,
  selectedSortTypeAtom,
  selectedSortDirAtom,
  idsAtom,
  preDesignerListAtom,
  designerTotalAtom,
} from '../atom/atom';

const useResetDesignerState = (resetAll = false) => {
  const resetSelectedIdIndex = useResetRecoilState(selectedIdIndexAtom);
  const resetDesignerList = useResetRecoilState(designerListAtom);
  const resetSelectedSortType = useResetRecoilState(selectedSortTypeAtom);
  const resetSelectedSortDir = useResetRecoilState(selectedSortDirAtom);
  const resetIds = useResetRecoilState(idsAtom);
  const resetPreDesignerList = useResetRecoilState(preDesignerListAtom);
  const resetDesignerTotal = useResetRecoilState(designerTotalAtom);

  return () => {
    if (resetAll) {
      resetSelectedIdIndex();
      resetDesignerList();
      resetSelectedSortType();
      resetSelectedSortDir();
      resetIds();
      resetPreDesignerList();
      resetDesignerTotal();
    } else {
      resetSelectedIdIndex();
      resetDesignerList();
      resetIds();
      resetPreDesignerList();
      resetDesignerTotal();
    }
  };
};

export default useResetDesignerState;
