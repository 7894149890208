// utils.js

export const updateLikeState = async ({ id, setLikeState, cards, count, data }) => {
  if (data.result) {
    const newCards = cards?.map(card =>
      card.product_id === id
        ? {
            ...card,
            product_like_count: `${Number(card.product_like_count) + 1}`,
            my_like: true,
          }
        : card
    );
    setLikeState({ id: id, count: Number(count) + 1, value: true });
    return newCards;
  } else {
    const newCards = cards?.map(card =>
      card.product_id === id
        ? {
            ...card,
            product_like_count: `${Number(card.product_like_count) - 1}`,
            my_like: false,
          }
        : card
    );

    setLikeState({ id: id, count: Number(count) - 1, value: false });

    return newCards;
  }
};

export const designerUpdateLikeState = async (designerList, designerId, newCards) => {
  return designerList?.map(item =>
    Number(item.designer.id_studio) === Number(designerId)
      ? {
          designer: item.designer,
          items: newCards,
        }
      : item
  );
};
