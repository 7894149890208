import React, { useContext, useEffect, useRef, useState } from 'react';
import { Header, HeaderBg, Img } from '../designers';
import { getDesignerFollow, getDesignerInfoId, getDesignerUnFollow } from '../../../action/request';

import UserContext from '../../../context/user/user';
import ModalContext from '../../../context/modal/modal';
import { useNavigate } from 'react-router-dom';
import { designerListAtom, followIdAtom, isMobileAtom } from '../../../atom/atom';
import { useRecoilState } from 'recoil';
import DesignersMobileHeader from './DesignersInfo';
import { useLocation } from 'react-router-dom';
import { updateFollowCount } from '../../../hook/updateFollowCount';
import { isMobile } from 'react-device-detect';

const DesignersHeader = ({
  data,
  isFollow,
  setIsFollow,
  DesignerSectionPropsStyle,
  index,
  designerSection,
  setFollowId,
  fromDesignerSection,
  value,
}) => {
  const [isReadMore, setIsReadMore] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const { action: modalAction } = useContext(ModalContext);
  const { state: userData } = useContext(UserContext);
  const location = useLocation();
  const url = location.pathname;
  const isLogin = userData?.isLogin;
  const textRef = useRef(null);
  const studioId = Number(data?.id_studio);
  const isApp = userData?.isApp;
  const [designerFollow, setDesignerFollow] = useState();
  const [, setdesignerId] = useRecoilState(followIdAtom);
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom);
  const [designerList, setDesignerList] = useRecoilState(designerListAtom);

  const navigate = useNavigate();

  function hasNameAfterDesigners(url) {
    const basePath = '/designers';
    return url.startsWith(basePath) && url.length > basePath.length;
  }

  const isDesignerPage = hasNameAfterDesigners(url);

  const onClickRead = () => setIsReadMore(!isReadMore);

  const onClickFollow = async e => {
    if (!isLogin) {
      modalAction.setIsLogin(true);
      return;
    }

    const action = e.target.id === 'follow' ? getDesignerFollow : getDesignerUnFollow;
    const res = await action(studioId);

    if (res.data.returnCode === 'C00000') {
      const isNowFollowing = e.target.id === 'follow';
      setIsFollow(isNowFollowing);
      setFollowId?.({ id: studioId, isFollow: isNowFollowing });
      setdesignerId({ id: studioId, isFollow: isNowFollowing });
      setDesignerList(updateFollowCount(designerList, studioId, isNowFollowing));
    }

    fetchData(index);
  };

  const fetchData = async () => {
    const { data: designerData } = await getDesignerInfoId({ isLogin, studioId });
    setDesignerFollow(designerData.data.follow_count);
  };

  useEffect(() => {
    if (!isLogin) setIsFollow(false);
  }, [isLogin]);

  useEffect(() => {
    const checkTextOverflow = () => {
      if (textRef.current) {
        setIsOverflowing(textRef.current.scrollHeight >= 75);
      }
      setIsMobile(window.innerWidth < 600);
    };

    checkTextOverflow();
    window.addEventListener('resize', checkTextOverflow);

    return () => {
      window.removeEventListener('resize', checkTextOverflow);
    };
  }, []);

  const designersPageM =
    isDesignerPage && isMobile ? '40px 0px 0px 0px' : DesignerSectionPropsStyle?.headerPadding;

  return (
    <Header>
      <div className={isDesignerPage ? 'onlydesignersHeader' : 'designersHeader'}>
        {data && (
          <HeaderBg className='headerbg'>
            {isDesignerPage && (
              <button className='viewDesignerList' onClick={() => navigate('/designers')}>
                View Designer List
              </button>
            )}

            <DesignersMobileHeader
              data={data}
              designerSection={designerSection}
              isMobile={isMobile}
              designerFollow={designerFollow}
              value={value}
              onClickFollow={onClickFollow}
              isFollow={isFollow}
              navigate={navigate}
              isApp={isApp}
              index={index}
              designerPage={isDesignerPage}
            />

            {!DesignerSectionPropsStyle?.descriptionNone && (
              <div className='description'>
                <span className={isReadMore ? '' : 'ellipsis'} ref={textRef}>
                  {data?.desc_en}
                </span>
                {isOverflowing && (
                  <button className='readMore' onClick={onClickRead}>
                    {isReadMore ? 'Read less' : 'Read more'}
                  </button>
                )}
              </div>
            )}
          </HeaderBg>
        )}
      </div>
    </Header>
  );
};

export default DesignersHeader;
