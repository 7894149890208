import { SYMBOL_KRW, SYMBOL_USD } from '../component/Price/constant';

export const getMainThumb = model => {
  if (!model?.medias || model.medias.length === 0) {
    return model?.mainImg;
  }
  const index = parseInt(model.mainImg, 10);

  if (isNaN(index) || index < 0 || index >= model.medias.length) {
    return model?.medias[0]?.thumbs[0]?.url;
  }

  return model.medias[index]?.thumbs[0]?.url;
};

export const getRequestCommentImages = () => {
  // return array_map(
  //   function (CommentMediaDraftDto $media){
  //     $full = $media->url;
  //     $thumb = $media->thumbs[0] ? $media->thumbs[0]->url : "";
  //     return sprintf("<a href='%s' data-tb='%s'><img src='%s'></a>", $full, $thumb, $thumb);
  //   }, $medias);
};

export const DEFAULT_AVATAR = '/assets/img/default-profile-pic.png';
export const getSymbol = () => {
  return window.navigator.language === 'ko' ? SYMBOL_KRW : SYMBOL_USD;
};
