// updateFollowCount 함수
export const updateFollowCount = (designerList, designerId, isNowFollowing) => {
  return designerList?.map(entry => {
    if (Number(entry?.designer?.id_studio) === Number(designerId)) {
      const updatedDesigner = { ...entry.designer };
      // follow_count 및 my_follow 값 업데이트
      updatedDesigner.follow_count = isNowFollowing
        ? updatedDesigner.follow_count + 1
        : updatedDesigner.follow_count - 1;
      updatedDesigner.my_follow = isNowFollowing ? 1 : 0;

      // 업데이트된 designer와 기존 items를 합쳐 새로운 객체로 반환
      return {
        designer: updatedDesigner,
        items: entry.items,
      };
    }
    return entry;
  });
};
