import RequestMedia from '../../../../component/request/RequestMedia';
import { ReviewUploadItemImage } from '../../../../action/request';
import { useContext } from 'react';
import UserContext from '../../../../context/user/user';

const ReviewBody = ({ body, setBody, medias, setMedias, mainIdx, setMainIdx }) => {
  const { state: userState } = useContext(UserContext);

  const fileUpload = () => {
    document.getElementById('fileInput').click();
  };

  const fileChange = async e => {
    try {
      const files = e.target.files;
      const newMedias = [...medias];
      for (let i = 0; i < files.length; i++) {
        const { data: res } = await ReviewUploadItemImage({
          file: files[i],
          idUser: userState.id,
        });

        if (res.returnCode === 'C00008') {
          alert(res?.message);
        } else {
          newMedias.push(res.img);
        }
      }
      setMedias(newMedias);
      e.target.value = ''; // value reset
    } catch (e) {
      alert('request failed');
    }
  };

  const onDelete = idx => {
    const newMedias = medias.filter((_, i) => i !== idx);

    if (idx === mainIdx) {
      setMainIdx(0);
    } else {
      if (idx < mainIdx) {
        setMainIdx(mainIdx - 1);
      } else {
        setMainIdx(mainIdx);
      }
    }

    setMedias(newMedias);
  };

  return (
    <>
      <div className='form-group form-group-h'>
        <label className='form-label'>Description</label>
        <div className='form-control'>
          <textarea
            className='form-input'
            name='body'
            value={body}
            onChange={e => {
              setBody(e.target.value);
            }}
          ></textarea>
          <div className='file-uploader'>
            <input type='file' accept='image/*' id='fileInput' onChange={fileChange} multiple />
            <button type='button' className='btn-upload-file loading_color' onClick={fileUpload}>
              Add Image
            </button>
          </div>
          <div className='attachments'>
            <ul className='files'>
              {medias.map((m, idx) => {
                return (
                  <RequestMedia
                    key={m.url}
                    media={m}
                    setMedias={setMedias}
                    isMain={idx === mainIdx}
                    setMainIdx={setMainIdx}
                    idx={idx}
                    onDelete={onDelete}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewBody;
