// motilinkRedirect.js
import { isMobile } from 'react-device-detect';

export const motilinkRedirect = ({ loginData }) => {
  // console.info('loginData', loginData);
  const { username, email } = loginData?.userInfo || {};
  const { access_token, refresh_token } = loginData?.token || {};
  const expirationDate = loginData?.expirationDate;
  const studioName = loginData?.studio;
  const hostName = window.location.hostname;
  const userAgent = navigator.userAgent.toLowerCase();

  const storedValue = localStorage.getItem('loginPath');
  const url = new URL(storedValue, window.location.origin); // 로컬 호스트 경로 처리
  const redirectUrl = url.searchParams.get('redirectUrl');

  const motilinkUrl =
    hostName?.includes('dev') || hostName?.includes('localhost')
      ? 'https://dev.motilink.com/fabdev/webapp/'
      : 'https://stg2.motilink.com/Fab365/webapp/';

  const generateUrl = base => {
    const studioNameParam = studioName ? `&studioName=${studioName?.url_name}` : '';
    const menuOption = studioName ? 2 : 1;

    return `${base}?name=${username}&accessToken=${access_token}&refreshToken=${refresh_token}&email=${email}&expirationDate=${expirationDate}${studioNameParam}&menuOption=${menuOption}`;
  };

  if (!userAgent.includes('motilink')) {
    console.info('motilinkUrl', generateUrl(motilinkUrl || redirectUrl));
    window.location.href = generateUrl(motilinkUrl || redirectUrl);
  } else {
    console.info('motilinkUrl', generateUrl('motilink://signIn'));
    window.location.href = generateUrl('motilink://signIn');
  }
};
