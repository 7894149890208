import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DesingersBoxs from './DesingersBoxs';
import ModalContext from '../../../context/modal/modal';
import { getDesignerFollow, getDesignerUnFollow } from '../../../action/request';
import { followIdAtom } from '../../../atom/atom';
import { useRecoilState } from 'recoil';
import { updateFollowCount } from '../../../hook/updateFollowCount';

const DesignerListHeader = ({ data, isLogin, setDesignerList }) => {
  const studioId = data?.id_studio;
  const [designerFollow, setDesignerFollow] = useState();
  const [isFollow, setIsFollow] = useState(data?.my_follow);
  const { action: modalAction } = useContext(ModalContext);
  const [, setdesignerId] = useRecoilState(followIdAtom);
  const navigate = useNavigate();

  const handleFollowClick = async e => {
    if (!isLogin) {
      modalAction.setIsLogin(true);
      return;
    }
    const isNowFollowing = e === 'follow';

    const action = isNowFollowing ? getDesignerFollow : getDesignerUnFollow;

    const res = await action(studioId);

    if (res.data.returnCode === 'C00000') {
      setIsFollow(isNowFollowing);
      setDesignerFollow(isNowFollowing ? data?.follow_count + 1 : data?.follow_count - 1);

      setDesignerList(prevData => updateFollowCount(prevData, studioId, isNowFollowing));
    }
  };

  const handleViewDetailsClick = () => {
    localStorage.setItem('scrollDesigner', window.scrollY);

    navigate(`/designers/${data?.name.replace(/\s+/g, '')}`, {
      state: { location: 'fromDesignerSection', action: 'viewDetails' },
    });
  };

  return (
    <Header>
      {data && (
        <HeaderBg className='headerbg'>
          <div className='profile-header'>
            <div className='profileWrap'>
              <img src={data?.logo} className='profile' />
              <span className='name'>{data?.name} </span>
            </div>

            <img src={data?.logo} className='profile desktop-only' />
            <div className='profile-info'>
              <span className='name desktop-only'>{data?.name} </span>
              <div className='btn-wrap'>
                <button
                  onClick={() => handleFollowClick(isFollow ? 'unFollow' : 'follow')}
                  className='btn-follow'
                >
                  <img
                    src={`/assets/img/designers/${isFollow ? 'unfollow' : 'follow_button'}.png`}
                    right={8}
                  />
                </button>
                <ViewDetailsBtn onClick={handleViewDetailsClick}>
                  <img src={`/assets/img/designers/view_detail_icon.png`} alt='View Details' />
                  <span>View Details</span>
                </ViewDetailsBtn>
              </div>
            </div>
          </div>
          <div className='countBoxWrap'>
            <DesingersBoxs data={data} designerFollow={designerFollow} />
          </div>
        </HeaderBg>
      )}
    </Header>
  );
};

export default DesignerListHeader;

const Header = styled.div``;

const HeaderBg = styled.div`
  padding: 15px 30px;
  background-image: url(/assets/img/designers/designersBg.png);
  background-size: cover;
  border-radius: 10px;
  background-repeat: no-repeat;
  min-height: 150px;

  display: flex;
  align-items: center;

  .profile-header {
    display: flex;
    width: 100%;
  }

  .profile {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-right: 12px;
  }

  .name {
    color: #fff;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: ${props => props.bottom}px;
    margin-top: ${props => props.top}px;
    overflow-wrap: break-word;
    display: inline-block;

    &::after {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url('/assets/img/designers/designer_badge.png');
      background-size: cover;
    }
  }

  .badge {
    width: 24px;
    height: 24px;
  }

  .btn-follow {
    img {
      width: 7.75rem;
      height: 42px;
      margin-right: 8px;
    }
  }

  .btn-wrap {
    display: flex;
    margin-top: 20px;
  }

  .profileWrap {
    display: none;
  }

  .countBoxWrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 6px;
  }

  .profile-info {
    min-width: 40%;
  }

  @media (max-width: 1030px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .countBoxWrap {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      gap: 6px;
      margin-top: 20px;
    }
  }

  //모바일

  @media (max-width: 768px) {
    display: flex;
    -webkit-box-align: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px 28px;
    background-image: url(/assets/img/designers/designerlist_m.png);
    object-fit: contain;

    .profile-header {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .profile {
      width: 60px;
      height: 60px;
      border-radius: 100px;
    }

    .name {
      color: #fff;
      font-size: 28px;
      font-weight: 600;
    }

    .profileWrap {
      display: flex;
      align-items: center;
    }

    .desktop-only {
      display: none;
    }

    .countBoxWrap {
      width: 100%;
      display: grid;
      grid-template-columns: 100px 100px;
      gap: 7px;
      margin-top: 20px;
      justify-content: start;
    }
  }
`;

export const ViewDetailsBtn = styled.button`
  color: #fff !important;
  width: 9.875rem;
  height: 42px;
  border: 1px solid #ffffffb2 !important;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Outfit';
  }

  img {
    width: 18px;
    height: 13px;
    margin: 0 6px;
  }
`;
