import { useContext, useState, useEffect } from 'react';
import UserContext from '../../../context/user/user';
import { ITEMS, MY_REVIEW_EDIT, REVIEW } from '../../../route/constant';
import { formatHrefLink, isEditable } from '../../../util/comment';
import { getMainThumb } from '../../../util/image';
import ReadQuill from '../../../component/editor/ReadQuill';

import ReviewComment from './ReviewComment';
import ReviewHeader from './ReviewHeader';
import ReviewTags from './ReviewTags';
import { getReview, reviewLikeBtn } from '../../../action/request';
import ModalContext from '../../../context/modal/modal';
import { RETURN_CODE } from '../../../action/constant';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { getOidUrl } from '../../../util/url';
import { useRecoilState } from 'recoil';
import { paginationAtom } from '../../../atom/atom';
import { ImgStyled } from '../../../styles/ImgStyled';

const ReviewDetail = ({ oid, subject, setShowImgViewer, setImgData }) => {
  const { state } = useContext(UserContext);
  const { action: modalAction } = useContext(ModalContext);
  const [total, setTotal] = useState(0);
  const [review, setReview] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [commentGroups, setCommentGroups] = useState([]);
  const [commentReplys, setCommentReplys] = useState([]);
  const [flag, setFlag] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [handleFlag, setHandleFlag] = useState(false);
  const [body, setBody] = useState();

  const [, setIndex] = useRecoilState(paginationAtom);

  const navigate = useNavigate();

  const index = useLocation();

  useEffect(() => {
    if (index.state) {
      setIndex(index.state);
    }
    fetchData();
  }, [page, handleFlag, state.isLogin]);

  const fetchData = async () => {
    try {
      const { data } = await getReview({ oid, subject, page, isLogin: state.isLogin });

      if (data.returnCode !== RETURN_CODE.C00000) {
        navigate(REVIEW);
        return;
      }

      const body = data.data.review.body;

      const decodeHtml = html => {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
      };

      const decodedHtml = decodeHtml(body);

      setTotal(data.data.pagingComment.count);
      setPageSize(data.data.pagingComment.size);
      setReview(data.data.review);
      setCommentGroups(data.data.commentGroups);
      setCommentReplys(data.data.commentReplys);
      setIsLike(data.data.isLike);
      setFlag(true);
      setBody(decodedHtml);
    } catch (e) {
      navigate(REVIEW);
      return;
    }
  };

  const handleReviewCommnet = () => {
    setHandleFlag(!handleFlag);
  };

  const likeBtn = async (e, oidTarget) => {
    e.preventDefault();

    if (!state.isLogin) {
      modalAction.setIsLogin(true);
      return;
    }

    try {
      await reviewLikeBtn(oidTarget);
      fetchData();
    } catch (error) {}
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <div id='main2'>
      <section className='story-section'>
        <div className='container'>
          {flag && (
            <div className='story-detail'>
              <ReviewHeader data={review} />
              <div className='story-detail-body'>
                {review?.medias?.map((m, idx) => {
                  return (
                    <div className='top-img' key={idx}>
                      <ImgStyled src={m.url} alt='' />
                    </div>
                  );
                })}
                <div className='story-content'>
                  <ReadQuill requestBody={body} />
                </div>
                <ReviewTags data={review.tags} />
                <div className='related-product'>
                  <Link href='' onClick={e => goTo(e, ITEMS + '/' + review?.item_id)}>
                    <div className='related-product-inner'>
                      <div className='tb'>
                        <ImgStyled src={review?.item_listImg} alt='' />
                      </div>
                      <div className='desc'>
                        This Product is <br /> <strong>{review?.item_name}</strong>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className='story-share'>
                  <span className='msg'>Tell your friends!</span>
                  <a
                    href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                      review?.subject
                    )}&url=${`https://fab365.net${getOidUrl(
                      REVIEW,
                      oid,
                      review?.subject,
                      review?.body
                    )}`}`}
                    target='_new'
                  >
                    <i className='fab fa-x-twitter'></i>
                  </a>
                  <a
                    href={`https://www.facebook.com/dialog/share?app_id=${encodeURIComponent(
                      process.env.REACT_APP_FB_APP_ID
                    )}&display=popup&href=${`https://fab365.net${getOidUrl(
                      REVIEW,
                      oid,
                      review?.subject,
                      review?.body
                    )}`}`}
                    target='_new'
                  >
                    <i className='fab fa-facebook-f'></i>
                  </a>

                  <a
                    href={`https://www.pinterest.com/pin/create/button/?url=${`https://fab365.net${getOidUrl(
                      REVIEW,
                      oid,
                      review.subject,
                      review.body
                    )}`}&description=${encodeURIComponent(
                      review.subject
                    )}&media=${encodeURIComponent(getMainThumb(review, flag))}`}
                    target='_new'
                  >
                    <i className='fab fa-pinterest'></i>
                  </a>
                </div>
              </div>
              <div className='story-detail-footer'>
                <a
                  className='btn btn-sm btn-green btn-heart fab-oid-like-toggle'
                  href='#'
                  onClick={e => {
                    likeBtn(e, review?.id);
                  }}
                >
                  <span className={'count ' + (isLike ? 'active' : '')}>{review?.like_count}</span>
                </a>
                <a href='' onClick={e => goTo(e, REVIEW)} className='btn btn-sm'>
                  Go to list
                </a>
                {isEditable(review, state.id) && (
                  <a
                    href=''
                    onClick={e => goTo(e, MY_REVIEW_EDIT + '/' + review.id)}
                    className='btn btn-sm'
                  >
                    Edit
                  </a>
                )}
              </div>
            </div>
          )}
        </div>

        <div className='story-comments-title'>
          <div className='container'>
            <h2>
              <span>
                Comments <small className='badge'>{review.comment_count}</small>
              </span>
            </h2>
          </div>
        </div>
        <ReviewComment
          commentGroups={commentGroups}
          commentReplys={commentReplys}
          idRequest={review.id}
          count={total}
          size={pageSize}
          page={page}
          setPage={setPage}
          onReload={handleReviewCommnet}
          setShowImgViewer={setShowImgViewer}
          setImgData={setImgData}
        />
      </section>
    </div>
  );
};

export default ReviewDetail;
