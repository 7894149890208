import { useEffect, useRef, useState } from 'react';
import {
  AdditionalInfoContainer,
  HiddenTextSpan,
  TagBox,
  TagInput,
  TagItem,
  TitleWarper,
} from './additionallinfoStyle';

const TagList = ({ setTag, tag }) => {
  // 추가정보영역
  const [inputValue, setInputValue] = useState('');
  const [hasValidValue, setHasValidValue] = useState([]);

  //기본 input width
  const [inputWidth, setInputWidth] = useState(20);

  const inputRef = useRef(null);
  const textWidthRef = useRef(null);

  const handleKeyUp = event => {
    const isCommaOrSpace = event.key === ',' || event.key === ' '; //태그성성
    const trimmedInputValue = inputValue.trim().replace(/,$/, '').toLowerCase(); // 스페이스바나 콤마 제거
    const validValue = /^[a-z0-9]+$/.test(trimmedInputValue); // 영문 소문자와 숫자만 허용
    const validLength = trimmedInputValue.length >= 2; //  최소 2글자, 최대 20글자까지 입력할 수 있다.
    if (isCommaOrSpace) {
      event.preventDefault();
      if (trimmedInputValue === '' || trimmedInputValue === ',') return;

      if (validValue && validLength) {
        setTag(pre => [...pre, { value: trimmedInputValue, isValid: true }]);
        setInputValue('');
      } else {
        setTag(pre => [...pre, { value: trimmedInputValue, isValid: false }]);

        setInputValue('');
      }
    }
  };

  const handleChange = event => {
    setInputValue(event.target.value.trim());
  };

  const handleRemoveTag = index => {
    setTag(pre => pre.filter((_, i) => i !== index));
  };

  const handleContainerClick = () => {
    //box를 click 하면 Input에 포커스 되게처리
    inputRef.current.focus();
  };

  useEffect(() => {
    setHasValidValue(tag.every(tag => tag.isValid));
  }, [tag]);

  useEffect(() => {
    if (textWidthRef.current) {
      const spanWidth = textWidthRef.current.offsetWidth;
      setInputWidth(spanWidth + 20);
    }
  }, [inputValue]);

  return (
    <TagBox className='AdditionalInfoContainer' onClick={handleContainerClick}>
      <TitleWarper>
        <h3>Tag</h3>
        {hasValidValue || <p>* Tags can only contain 2 to 20 lower-case letters and numbers.</p>}
      </TitleWarper>
      <div className='tag-input-container'>
        {tag?.map((tag, index) => {
          return (
            <TagItem key={index} className='tag' isValid={tag.isValid}>
              {tag.value}
              <button onClick={() => handleRemoveTag(index)} type='button'>
                x
              </button>
            </TagItem>
          );
        })}
        <TagInput
          ref={inputRef}
          type='text'
          value={inputValue}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          className='tag-input'
          maxLength={20}
          width={tag.length > 0 ? inputWidth : ''}
          placeholder={
            tag.length > 0
              ? ''
              : 'e.g. "robot" (without quotes). Use space or comma to separate tags.'
          }
        />
      </div>
      {/* 화면에 표시되는 text의 width 를 감지하기 위한 span 이며 해당 span 의 width 가 Input 의 width 로 설정됩니다. */}
      <HiddenTextSpan ref={textWidthRef}>{inputValue}</HiddenTextSpan>
    </TagBox>
  );
};

export default TagList;
