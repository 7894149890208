import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import UserContext from '../../context/user/user';
import {
  DESIGNERS,
  MY_CREDIT,
  MY_DOWNLOADS,
  MY_LIKE,
  MY_ORDER,
  MY_PROFILE,
  MY_REQUEST,
  MY_REVIEW,
  MY_REWARD_TICKETS,
} from '../../route/constant';
import { removeSpecialCharactersAndSpaces } from '../section/util';

function UserMenu({ goTo, onLogout, myRef, style }) {
  const { state: userState } = useContext(UserContext);
  const studioName = userState.studioName;

  return (
    <div className='udMenu active' ref={myRef} style={style}>
      {/* TODO: 이름 넘치는거 밑으로 내리기 */}
      <div className='name'>{userState?.name}</div>
      <div className='menuGroup'>
        <Link to='#' className='menu' onClick={e => goTo(e, MY_PROFILE)}>
          <div className='icon'>
            <img src='/assets/img/2.0WebImg/ui/ud001.svg' alt='' />
          </div>
          <div className='txt'>Profile</div>
        </Link>

        <div className='line1'></div>

        <Link to='#' className='menu' onClick={e => goTo(e, MY_ORDER)}>
          <div className='icon'>
            <img src='/assets/img/2.0WebImg/ui/ud002.svg' alt='' />
          </div>
          <div className='txt'>Order</div>
        </Link>
        <Link to='#' className='menu' onClick={e => goTo(e, MY_DOWNLOADS)}>
          <div className='icon'>
            <img src='/assets/img/2.0WebImg/ui/ud003.svg' alt='' />
          </div>
          <div className='txt'>Download</div>
        </Link>

        <div className='line1'></div>

        <Link to='#' className='menu' onClick={e => goTo(e, MY_LIKE)}>
          <div className='icon'>
            <img src='/assets/img/2.0WebImg/ui/ud004.svg' alt='' />
          </div>
          <div className='txt'>Like</div>
        </Link>
        <Link to='#' className='menu' onClick={e => goTo(e, MY_REVIEW)}>
          <div className='icon'>
            <img src='/assets/img/2.0WebImg/ui/ud005.svg' alt='' />
          </div>
          <div className='txt'>Review</div>
        </Link>
        <Link to='#' className='menu' onClick={e => goTo(e, MY_REQUEST)}>
          <div className='icon'>
            <img src='/assets/img/2.0WebImg/ui/ud006.svg' alt='' />
          </div>
          <div className='txt'>Request</div>
        </Link>

        <div className='line1'></div>

        <Link to='#' className='menu' onClick={e => goTo(e, MY_CREDIT)}>
          <div className='icon'>
            <img src='/assets/img/2.0WebImg/ui/ud007.svg' alt='' />
          </div>
          <div className='txt'>Credit</div>
        </Link>
        <Link to='#' className='menu' onClick={e => goTo(e, MY_REWARD_TICKETS)}>
          <div className='icon'>
            <img src='/assets/img/2.0WebImg/ui/ud008.svg' alt='' />
          </div>
          <div className='txt'>Reward ticket</div>
        </Link>

        {/* <!-- 디자이너에게만 노출 요소 - start --> */}
        {/* TODO:디자이너url */}
        {userState.isDesigner && (
          <>
            <div className='line1'></div>
            <Link
              to='#'
              className='menu'
              onClick={e => goTo(e, DESIGNERS + '/' + removeSpecialCharactersAndSpaces(studioName))}
            >
              <div className='icon'>
                <img src='/assets/img/2.0WebImg/ui/ud009.svg' alt='' />
              </div>
              <div className='txt'>Designer</div>
            </Link>
          </>
        )}
        {/* <!-- 디자이너에게만 노출 요소 - end --> */}

        <div className='line1'></div>
        <Link to='#' className='menu' onClick={onLogout}>
          <div className='icon'>
            <img src='/assets/img/2.0WebImg/ui/ud010.svg' alt='' />
          </div>
          <div className='txt'>Logout</div>
        </Link>
      </div>
    </div>
  );
}

export default UserMenu;
