import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import UserContext from '../../context/user/user';
import { githubLogin } from '../../action/request';
import { ITEMS, SNS_SIGNUP } from '../../route/constant';
import { Ss, ls } from '../../constant';
import { gtagOnClickLogin } from '../../action/gTag';
import { RETURN_CODE } from '../../action/constant';
import { isWelcomeModal } from '../../atom/atom';
import { useRecoilValue } from 'recoil';
import { useCookies } from 'react-cookie';

import { isMotilink } from '../../atom/atom';
import { motilinkRedirect } from '../../util/useSocialLoginPlanet';

function GithubCallback() {
  const [cookies] = useCookies(['autoLogin']);
  const isWelcomeModalValue = useRecoilValue(isWelcomeModal);
  const isMotilinkValue = useRecoilValue(isMotilink);
  const location = useLocation();
  const [code, setCode] = useState(undefined);
  const { action: userAction, state: userState } = useContext(UserContext);
  const navigate = useNavigate();
  const loginPath = localStorage.getItem('loginPath');

  // 인가코드 가져오기
  const getCode = async () => {
    const urlParams = new URLSearchParams(location.search);
    const authorizationCode = urlParams.get('code');
    setCode(authorizationCode);
    // console.log('authorizationCode', authorizationCode);
  };

  // 코드로 유저 정보 가져오기
  const getUserInfo = async code => {
    try {
      // const from = userState.isApp ? 'M' : 'W';
      const from = userState?.isApp || isMotilinkValue ? 'M' : cookies.autoLogin ? 'A' : 'W';
      const { data: res } = await githubLogin(code, from);
      // console.log('res', res);
      // console.log('Github get User 요청 완료');

      // block된 유저일 경우
      if (res.returnCode === RETURN_CODE.C00008) {
        alert(res.message);
        const url = isMotilinkValue ? '/appdesigner-login' : '/';
        return navigate(url);
      }

      const userInfo = res.data.userInfo;
      const userToken = res.data.token;
      const loginData = res.data;

      gtagOnClickLogin('Github');

      if (userInfo) {
        // token을 localStorage에 저장
        localStorage.setItem(ls.id, userInfo.id_uesr);
        localStorage.setItem(ls.accessToken, userToken.access_token);
        localStorage.setItem(ls.refreshToken, userToken.refresh_token);
        // userInfo context에 저장
        userAction.setEmail(userInfo.email);
        userAction.setId(userInfo.id_uesr);
        userAction.setIsLogin(true);
        userAction.setThumbnail(userInfo.thumbnail);
        userAction.setIsLogin(false);

        if (loginPath?.includes('appdesigner') || isMotilinkValue) {
          return motilinkRedirect({ loginData });
        } else {
          if (localStorage?.getItem(Ss.itemNumber)) {
            navigate(`${ITEMS}/${localStorage?.getItem(Ss.itemNumber)}`);
            localStorage.removeItem(Ss.itemNumber);
          } else {
            navigate('/');
          }
        }

        // if (isWelcomeModalValue) {
        //   navigate('/');
        //   localStorage.removeItem(ls.loginPath);
        // } else {
        //   navigate(localStorage.getItem(ls.loginPath));
        //   localStorage.removeItem(ls.loginPath);
        // }
        //
        // else {
        //   // 로그인하면 로컬스토리지에 저장 된 기존에 있던 위치로 이동
        //   navigate(localStorage.getItem(ls.loginPath));
        //   // 로그인 위치로 이동 후 로그인 위치 정보 삭제
        //   localStorage.removeItem(ls.loginPath);
        // }
        //
        // gtag 깃헙 로그인 추적 태그
      }
      // 이메일 null 이면 알림
      if (res.data.email === 'null') {
        // TODO : 문구 컨펌받기
        alert(
          'You must set up an email in your Github account before you can join. For more information, please contact Fab365.'
        );
      }
      // 회원가입 처리
      if (res.data.id_user === null) {
        // user context에 data 저장
        userAction.setSnsUserInfo(res.data);
        const url = isMotilinkValue ? `${SNS_SIGNUP}&app=ture` : `${SNS_SIGNUP}`;
        navigate(url);
      }
    } catch (error) {
      console.error(error);
      alert('Request failed.');
      const url = isMotilinkValue ? '/appdesigner-login' : '/';
      navigate(url);
    }
  };

  useEffect(() => {
    getCode();
  }, [location.search, navigate]);

  useEffect(() => {
    if (code !== undefined) {
      getUserInfo(code);
    }
  }, [code]);

  return null;
}

export default GithubCallback;
